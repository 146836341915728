@import url('https://fonts.googleapis.com/css2?family=Rubik&family=Titillium+Web:wght@300&display=swap');

body {
  font-family: 'Titillium Web', sans-serif;
  padding: 0px;
  margin: 0px;
  background-color: black;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 768px) {
  #countdownContainer {
    margin-top: 75%;
  }
}

@media screen and (max-width: 1023px) {
  #countdownContainer {
    margin-top: 40%;
  }
}

/*
#root {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

#zmmtg-root {
  width: 0vw !important;
  height: 0vh !important;
  overflow: hidden !important;
  opacity: 0 !important;
}
*/
