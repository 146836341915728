@import url(https://fonts.googleapis.com/css2?family=Rubik&family=Titillium+Web:wght@300&display=swap);
.player-wrapper {
  position: relative;
  width: 100%;
  background-color: transparent;
}

.player-wrapper > div {
  width: auto;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

#player > div {
  background-color: transparent !important;
}

.player-wrapper {
  position: relative;
  width: 100%;
  background-color: transparent;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

#player > div {
  background-color: transparent !important;
}

.react-responsive-modal-overlay {
  background: none;
  justify-content: center;
  align-items: flex-end;
}

.react-responsive-modal-modal {
  position: absolute;
  padding: 0;
  bottom: 2vh;
  left: 25%;
  max-width: none !important;
  max-width: initial !important;
}

@media (max-width: 500px) {
  .react-responsive-modal-modal {
    left: 0;
    width: 90vw;
  }
}

@-webkit-keyframes customEnterAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes customEnterAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes customLeaveAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes customLeaveAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

body {
  font-family: 'Titillium Web', sans-serif;
  padding: 0px;
  margin: 0px;
  background-color: black;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 768px) {
  #countdownContainer {
    margin-top: 75%;
  }
}

@media screen and (max-width: 1023px) {
  #countdownContainer {
    margin-top: 40%;
  }
}

/*
#root {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

#zmmtg-root {
  width: 0vw !important;
  height: 0vh !important;
  overflow: hidden !important;
  opacity: 0 !important;
}
*/

