.player-wrapper {
  position: relative;
  width: 100%;
  background-color: transparent;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

#player > div {
  background-color: transparent !important;
}
